.smallnav-container {
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  z-index: 999;

  .fab {
    background: radial-gradient( circle, rgba(252, 176, 69, 1) 0%, rgba(253, 29, 29, 1) 25%, rgba(109, 30, 113, 1) 100%);;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 3;
    border-radius: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fab-animation-reverse 0.4s ease-out forwards;

    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      border-radius: 4px;
      background: #fff;
    }

    &::before {
      width: 4px;
      height: 18px;
    }

    &::after {
      width: 18px;
      height: 4px;
    }
  }

  .fac {
    width: 32px;
    height: 150px;
    border-radius: 64px;
    position: absolute;
    background: #fff;
    z-index: 2;
    padding: 0.5rem 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    opacity: 0;
    top: -110px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: opacity 0.2s ease-in, top 0.2s ease-in, width 0.1s ease-in;

    /*&::after {
      content: "";
      width: 20px;
      height: 20px;
      background: #fff;
      border-radius: 3px;
      position: absolute;
      left: 50%;
      margin-left: -10px;
      bottom: -8px;
      transform: rotate(45deg);
      z-index: 0;
    }*/

    a {
      color: #344955;
      opacity: 0.8;

      &:hover {
        transition: 0.2s;
        opacity: 1;
        color: darken(#344955, 2%);
      }
    }
  }

  input {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    z-index: 5;
    opacity: 0;

    &:checked {
      ~ .fab {
        animation: fab-animation 0.4s ease-out forwards;
      }

      ~ .fac {
        width: 32px;
        height: 150px;
        animation: fac-animation 0.4s ease-out forwards 0.1s;
        top: -180px;
        opacity: 1;
      }
    }
  }
}

@keyframes fab-animation {
  0% {
    transform: rotate(0) scale(1);
  }
  20% {
    transform: rotate(60deg) scale(0.93);
  }
  55% {
    transform: rotate(35deg) scale(0.97);
  }
  80% {
    transform: rotate(48deg) scale(0.94);
  }
  100% {
    transform: rotate(45deg) scale(0.95);
  }
}

@keyframes fab-animation-reverse {
  0% {
    transform: rotate(45deg) scale(0.95);
  }
  20% {
    transform: rotate(-15deg);
  }
  55% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0) scale(1);
  }
}

@keyframes fac-animation {
  0% {
    transform: scale(1, 1);
  }
  33% {
    transform: scale(0.95, 1.05);
  }
  66% {
    transform: scale(1.05, 0.95);
  }
  100% {
    transform: scale(1, 1);
  }
}

.fac.open {
  top: -180px;
  opacity: 1;
}
