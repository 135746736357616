.aboutus {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.aboutus-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 400px;
    flex-direction: column;
    padding: 5rem;
    margin-left: 2rem;
    position: relative;
    z-index: 2;
}

.aboutus-title {
    font-size: xx-large;
}

.aboutus-description {
    font-weight: 300;
}

.contact-btn {
    padding: 1rem;
    width: 150px;
    margin-top: 2rem;
    border-radius: 25px;
    background: var(--roman);
    background: linear-gradient(90deg, var(--roman) 0%, var(--cream-can) 100%);
    transition: background 0.3s, background 0.3s;
    border: none;
    color: white;
    font-weight: 600;
}

.contact-btn:hover {
    background: var(--cream-can);
    background: linear-gradient(90deg, var(--cream-can) 0%, var(--roman) 100%);
}

@media (max-width: 768px) {
    .aboutus-container {
        padding: 1rem;
        margin: 0;
    }
    .aboutus-title {
        margin: 0;
    }
    .contact-btn {
        margin: 0;
    }
}
