.services {
    width: 90vw;
    margin: auto;
    position: relative;
    z-index: 3;
}

.section_header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.services-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.consulting_service {
    display: flex;
}

.additional_service {
    display: flex;
}

@media (max-width: 768px) {
    .services {
        margin: 0;
    }
    .consulting_service {
        width: 90%;
    }
    .web_service {
        display: flex;
        width: 90%;
    }
    .additional_service {
        width: 90%;
    }
    .list_rend-container {
        width: 90%;
    }
}
