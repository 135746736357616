.list_rend-container {
    padding: 2rem;
    background-color: var(--shark);
    border-radius: 5%;
    margin: 0.5rem;
    width: 600px;
}

.list_item {
    list-style: none;
}

.list_item_title {
    margin-bottom: 0;
    font-weight: 500;
    color: var(--cream-can);
    display: flex;
    align-items: center;
}

.list_item_description {
    margin-top: 0;
    font-weight: 300;
}