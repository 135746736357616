nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

nav ul li {
    margin: 0 1rem;
}

nav ul li a {
    text-decoration: none;
    padding: 5px 10px;
    border-radius: 3px;
    color: white;
    font-weight: 500;
    transition: color 0.3s;
}

nav ul li a:hover {
    color: var(--cream-can);
}
