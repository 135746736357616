:root {
    --honey-flower: #6d1e71;
    --cream-can: #f2b950;
    --shark: #1d1d24;
    --roman: #db5c5f;
    --bunker: #0c0e12;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background-color: var(--bunker);
    color: white;
    overflow-x: hidden;
    margin: 1rem;
}