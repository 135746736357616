.menu_bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.menu_bar-container {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 2;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_name {
    font-weight: 500;
    margin: 1rem;
    font-size: larger;
}

.logo-img {
    width: 50px;
    height: 50px;
}

.social-links {
    margin-left: 2rem;
}

.nav-social_container {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {

    .nav-social_container {
        display: none;
    }

    .menu_bar {
        justify-content: flex-start;
    }
    .menu_bar-container .logo-container {
        justify-content: flex-start;
    }
}
