.process {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2rem;
    position: relative;
    z-index: 1;
}

.process_main_header {
    color: white;
    font-size: 1.5em;
    z-index: inherit;
}

.process_main-container {
    position: relative;
    z-index: inherit;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

@media (max-width: 768px) {
    .process {
        margin: 0;
    }
    .process_main-container {
        display: flex;
        flex-direction: column;
    }
}
